import React from "react";


class Portfolio extends React.Component {
    state = {  } 
    render() { 
        return (
            <section id="portfolio" class="projects-area pt-130 rpt-100 pb-100 rpb-70 rel z-1">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-12">
                            <div class="section-title text-center mb-60 wow fadeInUp delay-0-2s">
                                <span class="sub-title mb-15">Latest Works</span>
                                <h2>Explore My Popular <span>Projects</span></h2>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center pb-25">
                        <div class="col-lg-6">
                            <div class="project-image wow fadeInLeft delay-0-2s">
                                <img src="images/projects/project1.jpg" alt="Project" />
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-6">
                            <div class="project-content wow fadeInRight delay-0-2s">
                                <span class="sub-title">Product Design</span>
                                <h2><a href="project-details.html">Mobile Application Design</a></h2>
                                <p>Sed ut perspiciatis unde omnin natus totam rem aperiam eaque inventore veritatis architecto beatae</p>
                                <a href="project-details.html" class="details-btn"><i class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center pb-25">
                        <div class="col-lg-6 order-lg-2">
                            <div class="project-image wow fadeInLeft delay-0-2s">
                                <img src="images/projects/project2.jpg" alt="Project" />
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-6 ms-auto">
                            <div class="project-content wow fadeInRight delay-0-2s">
                                <span class="sub-title">Product Design</span>
                                <h2><a href="project-details.html">Website Makeup Design</a></h2>
                                <p>Sed ut perspiciatis unde omnin natus totam rem aperiam eaque inventore veritatis architecto beatae</p>
                                <a href="project-details.html" class="details-btn"><i class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center pb-25">
                        <div class="col-lg-6">
                            <div class="project-image wow fadeInLeft delay-0-2s">
                                <img src="images/projects/project3.jpg" alt="Project" />
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-6">
                            <div class="project-content wow fadeInRight delay-0-2s">
                                <span class="sub-title">Product Design</span>
                                <h2><a href="project-details.html">Brand Identity and Motion Design</a></h2>
                                <p>Sed ut perspiciatis unde omnin natus totam rem aperiam eaque inventore veritatis architecto beatae</p>
                                <a href="project-details.html" class="details-btn"><i class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center pb-25">
                        <div class="col-lg-6 order-lg-2">
                            <div class="project-image wow fadeInLeft delay-0-2s">
                                <img src="images/projects/project4.jpg" alt="Project" />
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-6 ms-auto">
                            <div class="project-content wow fadeInRight delay-0-2s">
                                <span class="sub-title">Product Design</span>
                                <h2><a href="project-details.html">Mobile Application Development</a></h2>
                                <p>Sed ut perspiciatis unde omnin natus totam rem aperiam eaque inventore veritatis architecto beatae</p>
                                <a href="project-details.html" class="details-btn"><i class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="project-btn text-center wow fadeInUp delay-0-2s">
                        <a href="projects.html" class="theme-btn">View More Projects <i class="far fa-angle-right"></i></a>
                    </div>
                </div>
                <div class="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </section>
        );
    }
}
 
export default Portfolio;