import React from 'react';


class BlogDetails extends React.Component {
    render() {
        return (
            <body>
                
            </body>
        );
    }
}

export default BlogDetails;