import React from "react";

class Testimonials extends React.Component {
    state = {  } 
    render() { 
        return (
            <section class="testimonials-area rel z-1">
                <div class="for-bgc-black py-130 rpy-100">
                    <div class="container">
                        <div class="row gap-90">
                            <div class="col-lg-4">
                                <div class="testimonials-content-part rel z-2 rmb-55 wow fadeInUp delay-0-2s">
                                    <div class="section-title mb-40">
                                        <span class="sub-title mb-15">Clients Testimonials</span>
                                        <h2>I’ve 1253+ Clients <span>Feedback</span></h2>
                                        <p>Sed ut perspiciatis unde omnin natus totam rem aperiam eaque inventore veritatis</p>
                                    </div>
                                    <div class="slider-arrows">
                                        <button class="testimonial-prev"><i class="fal fa-arrow-left"></i></button>
                                        <button class="testimonial-next"><i class="fal fa-arrow-right"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="testimonials-wrap">
                                    <div class="testimonial-item wow fadeInUp delay-0-3s">
                                        <div class="author">
                                            <img src="images/testimonials/author1.png" alt="Author" />
                                        </div>
                                        <div class="text">At vero eoset accusamus et iusto odio dignissimos ducimus quie blanditiis praesentium voluptatum deleniti atque corrupti dolores</div>
                                        <div class="testi-des">
                                            <h5>Rodolfo E. Shannon</h5>
                                            <span>CEO & Founder</span>
                                        </div>
                                    </div>
                                    <div class="testimonial-item wow fadeInUp delay-0-4s">
                                        <div class="author">
                                            <img src="images/testimonials/author2.png" alt="Author" />
                                        </div>
                                        <div class="text">Nam libero tempore cumsoluta nobise est eligendi optio cumque nihil impedit quominus idquod maxime placeat facere possimus</div>
                                        <div class="testi-des">
                                            <h5>Kenneth J. Dutton</h5>
                                            <span>Web Developer</span>
                                        </div>
                                    </div>
                                    <div class="testimonial-item wow fadeInUp delay-0-2s">
                                        <div class="author">
                                            <img src="images/testimonials/author1.png" alt="Author" />
                                        </div>
                                        <div class="text">At vero eoset accusamus et iusto odio dignissimos ducimus quie blanditiis praesentium voluptatum deleniti atque corrupti dolores</div>
                                        <div class="testi-des">
                                            <h5>Rodolfo E. Shannon</h5>
                                            <span>CEO & Founder</span>
                                        </div>
                                    </div>
                                    <div class="testimonial-item wow fadeInUp delay-0-2s">
                                        <div class="author">
                                            <img src="images/testimonials/author2.png" alt="Author" />
                                        </div>
                                        <div class="text">Nam libero tempore cumsoluta nobise est eligendi optio cumque nihil impedit quominus idquod maxime placeat facere possimus</div>
                                        <div class="testi-des">
                                            <h5>Kenneth J. Dutton</h5>
                                            <span>Web Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </section>
        );
    }
}
 
export default Testimonials;