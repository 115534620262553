import React from "react";

class Skills extends React.Component {
    state = {  } 
    render() { 
        return (
            <section id="skills" class="skill-area rel z-1">
                <div class="for-bgc-black pt-130 rpt-100 pb-100 rpb-70">
                    <div class="container">
                        <div class="row gap-100">
                            <div class="col-lg-5">
                                <div class="skill-content-part rel z-2 rmb-55 wow fadeInUp delay-0-2s">
                                    <div class="section-title mb-40">
                                        <span class="sub-title mb-15">My Skills</span>
                                        <h2>Let’s Explore Popular <span>Skills & Experience</span></h2>
                                        <p>Sed ut perspiciatis unde omnis iste natus to voluptatem accusantium doloremque laudantium, totam rem aperiamc eaque ipsa quae ab illo inventore veritatis</p>
                                    </div>
                                    <a href="about.html" class="theme-btn">Learn More <i class="far fa-angle-right"></i></a>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="skill-items-wrap">
                                    <div class="row">
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-2s">
                                                <img src="images/skills/skill1.png" alt="Skill" />
                                                <h5>Figma</h5>
                                                <span class="percent">95%</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-3s">
                                                <img src="images/skills/skill2.png" alt="Skill" />
                                                <h5>Figma</h5>
                                                <span class="percent">83</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-4s">
                                                <img src="images/skills/skill3.png" alt="Skill" />
                                                <h5>Figma</h5>
                                                <span class="percent">93%</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-5s">
                                                <img src="images/skills/skill4.png" alt="Skill" />
                                                <h5>Figma</h5>
                                                <span class="percent">84%</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-2s">
                                                <img src="images/skills/skill5.png" alt="Skill" />
                                                <h5>Figma</h5>
                                                <span class="percent">65%</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-3s">
                                                <img src="images/skills/skill6.png" alt="Skill" />
                                                <h5>Figma</h5>
                                                <span class="percent">86%</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-4s">
                                                <img src="images/skills/skill7.png" alt="Skill" />
                                                <h5>Figma</h5>
                                                <span class="percent">62%</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-5s">
                                                <img src="images/skills/skill8.png" alt="Skill" />
                                                <h5>Figma</h5>
                                                <span class="percent">94%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </section>
        );
    }
}
 
export default Skills;