import React from 'react';

class Loader extends React.Component {
    render() {
        return (
            <body>
                
            </body>
        );
    }
}

export default Loader;