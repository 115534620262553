import React from "react";

class Home extends React.Component {
    state = {  }
    render() { 
        return ( 
            <section id="home" className="main-hero-area pt-150 pb-80 rel z-1">
                <div className="container container-1620">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-sm-7">
                            <div className="hero-content rmb-55 wow fadeInUp delay-0-2s">
                                <span className="h2">Hello, i’m </span>
                                <h1><b>Jocelin FOWE</b> Software Engineer</h1>
                                <p>We denounce with righteous indignation dislike demoralized by the charms of pleasure</p>
                                <div className="hero-btns">
                                    <a href="contact.html" className="theme-btn">Hire Me <i className="far fa-angle-right"></i></a>
                                    <a href="contact.html" className="read-more">Download Resume <i className="far fa-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-5 order-lg-3">
                            <div className="hero-counter-wrap ms-lg-auto rmb-55 wow fadeInUp delay-0-4s">
                                <div className="counter-item counter-text-wrap">
                                    <span className="count-text plus" data-speed="3000" data-stop="13">0</span>
                                    <span className="counter-title">Years Of Experience</span>
                                </div>
                                <div className="counter-item counter-text-wrap">
                                    <span className="count-text k-plus" data-speed="3000" data-stop="8">0</span>
                                    <span className="counter-title">Project Complete</span>
                                </div>
                                <div className="counter-item counter-text-wrap">
                                    <span className="count-text percent" data-speed="3000" data-stop="99">0</span>
                                    <span className="counter-title">Client Satisfactions</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="author-image-part wow fadeIn delay-0-3s">
                                <div className="bg-circle"></div>
                                <img src="images/hero/me.png" alt="Author" />
                                <div className="progress-shape">
                                    <img src="images/hero/progress-shape.png" alt="Progress" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </section>
        );
    }
}
 
export default Home;