import React from "react";

class Header extends React.Component {
    state = {  }
    render() { 
        return ( 
            <header className="main-header menu-absolute">
                <div className="header-upper">
                    <div className="container container-1620 clearfix">

                        <div className="header-inner rel d-flex align-items-center">
                            <div className="logo-outer">
                                <div className="logo"><a href="index.html"><img src="images/logos/logo.png" alt="Logo" title="Logo" /></a></div>
                            </div>

                            <div className="nav-outer clearfix mx-auto">
                                <nav className="main-menu navbar-expand-lg">
                                    <div className="navbar-header">
                                        <div className="mobile-logo my-15">
                                            <a href="index.html">
                                                    <img src="images/logos/logo.png" alt="Logo" title="Logo" />
                                            </a>
                                        </div>
                                        <button type="button" className="navbar-toggle me-4" data-bs-toggle="collapse" data-bs-target=".navbar-collapse">
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                        </button>
                                    </div>

                                    <div className="navbar-collapse collapse clearfix">
                                        <ul className="navigation onepage clearfix">
                                            <li><a href="#home">Home</a></li>
                                            <li><a href="#about">about</a></li>
                                            <li><a href="#resume">Resume</a></li>
                                            <li><a href="#services">services</a></li>
                                            <li><a href="#skills">skills</a></li>
                                            <li><a href="#portfolio">projects</a></li>
                                            <li><a href="#blog">blog</a></li>
                                            <li><a href="#contact">Contact</a></li>
                                        </ul>
                                    </div>

                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
 
export default Header;