import React from "react";

class Resume extends React.Component {
    state = {  }
    render() { 
        return (
            <section id="resume" class="resume-area pt-130 rpt-100 rel z-1">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="big-icon mt-85 rmt-0 rmb-55 wow fadeInUp delay-0-2s">
                                <i class="flaticon-asterisk-1"></i>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="row">
                                <div class="col-xl-8 col-lg-9">
                                    <div class="section-title mb-60 wow fadeInUp delay-0-2s">
                                        <span class="sub-title mb-15">My Resume</span>
                                        <h2>Real <span>Problem Solutions</span> Experience</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="resume-items-wrap">
                                <div class="row justify-content-between">
                                    <div class="col-xl-5 col-md-6">
                                        <div class="resume-item wow fadeInUp delay-0-3s">
                                            <div class="icon">
                                                <i class="far fa-arrow-right"></i>
                                            </div>
                                            <div class="content">
                                                <span class="years">2021 - Present</span>
                                                <h4>Lead Product Designer</h4>
                                                <span class="company">Google</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-5 col-md-6">
                                        <div class="resume-item wow fadeInUp delay-0-4s">
                                            <div class="icon">
                                                <i class="far fa-arrow-right"></i>
                                            </div>
                                            <div class="content">
                                                <span class="years">2016 - 2018</span>
                                                <h4>Junior UX/UI Designer</h4>
                                                <span class="company">LinkedIn</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-5 col-md-6">
                                        <div class="resume-item wow fadeInUp delay-0-2s">
                                            <div class="icon">
                                                <i class="far fa-arrow-right"></i>
                                            </div>
                                            <div class="content">
                                                <span class="years">2018 - 2021</span>
                                                <h4>Senior Product Designer</h4>
                                                <span class="company">Webflow</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-5 col-md-6">
                                        <div class="resume-item wow fadeInUp delay-0-4s">
                                            <div class="icon">
                                                <i class="far fa-arrow-right"></i>
                                            </div>
                                            <div class="content">
                                                <span class="years">2014 - 2016</span>
                                                <h4>Graphics Designer</h4>
                                                <span class="company">Apple</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </section>
        );
    }
}
 
export default Resume;